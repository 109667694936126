import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {AppRoutes} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {LottieAnimationViewModule} from 'ng-lottie';
import {HttpClientModule} from '@angular/common/http';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from 'ng-recaptcha';
import {AuthModule} from './services/auth/auth.module';
import {PipesModule} from './pipes/pipes.module';
//import {DragScrollModule} from 'ngx-drag-scroll';

const globalSettings: RecaptchaSettings = {siteKey: '6Lcn4LEZAAAAAEyhlAk3SBKiRT1uPCZ4u7zk9fj5'};

import {LOCALE_ID} from '@angular/core';
import localMx from '@angular/common/locales/es-MX';
import {registerLocaleData} from '@angular/common';

registerLocaleData(localMx);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    RouterModule,
    //DragScrollModule,
    AppRoutes,
    SharedModule,
    HttpClientModule,
    LottieAnimationViewModule.forRoot(),
    PipesModule,
    AuthModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'es-mx'},
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
    { provide: 'window', useValue: window },
    { provide: 'document', useValue: document },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
