<div class="footer d-flex flex-column" [ngClass]="{'container': !display.isMobile}">

    <div class="opciones">
        <div class="row">
            <div class="col-sm-3 col-md-3 col-xl-3">
                <div class="titulo w-100 text-center">
                    <small>Tubos y conexiones</small>
                </div>

                <div class="w-100">
                    <ul>
                        <li *ngIf="(config.DataConfig | async)?.quienesSomos.visible"><a class="ligaitem"
                                                                              [href]="(config.DataConfig | async)?.quienesSomos.url"
                                                                              target="_blank">Quiénes somos</a></li>
                        <li *ngIf="(config.DataConfig | async)?.historia.visible"><a class="ligaitem"
                                                                          [href]="(config.DataConfig | async)?.historia.url"
                                                                          target="_blank">Historia</a></li>
                    </ul>
                    <div class="redes d-flex align-items-center justify-content-center">
                        <a *ngIf="(config.DataConfig | async)?.twitter.visible" class="ligaitem"
                           [href]="(config.DataConfig | async)?.twitter.url" target="_blank">
                            <mat-icon *ngIf="platform.isBrowser()" class="iconsvg" [svgIcon]="'twetter'"></mat-icon>
                        </a>
                        <a *ngIf="(config.DataConfig | async)?.facebook.visible" class="ligaitem"
                           [href]="(config.DataConfig | async)?.facebook.url" target="_blank">
                            <mat-icon class="onhover">facebook</mat-icon>
                        </a>
                    </div>
                </div>
            </div>

            <hr *ngIf="display.isMobile" class="w-100">

            <div class="col-sm-3 col-md-3 col-xl-3">
                <div class="titulo w-100 text-center">
                    <small>Políticas y privacidad</small>
                </div>

                <div class="w-100">
                    <ul>
                        <li *ngIf="(config.DataConfig | async)?.terminos.visible">
                            <a class="ligaitem" [href]="(config.DataConfig | async)?.terminos.url"
                               target="_blank">Términos y Condiciones</a>
                        </li>
                        <li *ngIf="(config.DataConfig | async)?.politicasDevolucion.visible">
                            <a class="ligaitem" [href]="(config.DataConfig | async)?.politicasDevolucion.url"
                               target="_blank">Política de devolución</a>
                        </li>
                        <li *ngIf="(config.DataConfig | async)?.avisoPrivacidad.visible">
                            <a class="ligaitem" [href]="(config.DataConfig | async)?.avisoPrivacidad.url"
                               target="_blank">Aviso de Privacidad</a>
                        </li>
                        <li *ngIf="(config.DataConfig | async)?.politicasPago.visible">
                            <a class="ligaitem" [href]="(config.DataConfig | async)?.politicasPago.url"
                               target="_blank">Política de pagos</a>
                        </li>
                        <li *ngIf="(config.DataConfig | async)?.politicasEnvio.visible">
                            <a class="ligaitem" [href]="(config.DataConfig | async)?.politicasEnvio.url"
                               target="_blank">Política de envíos</a>
                        </li>
                        <li *ngIf="(config.DataConfig | async)?.faqs.visible">
                            <a class="ligaitem" [href]="(config.DataConfig | async)?.faqs.url"
                               target="_blank">FAQS</a>
                        </li>
                    </ul>
                </div>

            </div>

            <hr *ngIf="display.isMobile" class="w-100">

            <div class="col-sm-3 col-md-3 col-xl-3">
                <div class="titulo w-100 text-center">
                    <small>Contacto de la empresa</small>
                </div>

                <div class="w-100">
                    <ul>
                        <li>
                            <a class="ligaitem" [href]="'mailto:' + (config.DataConfig | async)?.correoContacto" target="_blank">{{(config.DataConfig | async)?.correoContacto}}</a>
                        </li>
                        <li>
                            <a class="ligaitem" [href]="'tel:+52'+(config.DataConfig | async)?.callCenter">{{ (config.DataConfig | async)?.callCenter | transformtxt: 'callcenter'}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <hr *ngIf="display.isMobile" class="w-100">

            <div class="col-sm-3 col-md-3 col-xl-3">
                <div class="titulo w-100 text-center">
                    <small>¿Cómo te ayudamos?</small>
                </div>

                <div class="w-100">
                    <ul>
                        <li *ngIf="(config.DataConfig | async)?.facturacion.visible">
                          <a class="ligaitem" [href]="(config.DataConfig | async)?.facturacion.url" target="_blank">Facturación electrónica</a>
                        </li>

                        <li *ngIf="(config.DataConfig | async)?.urlComoTeAtendimos.visible">
                            <a class="ligaitem" [href]="(config.DataConfig | async)?.urlComoTeAtendimos.url"
                               target="_blank">Cuéntanos ¿cómo te atendimos?</a>
                        </li>

                      <li>
                        <a class="ligaitem" [routerLink]="['/zona-entrega']">
                          Cobertura de entrega
                        </a>
                      </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <hr *ngIf="display.isMobile" class="w-100">

    <div class="copyr spacer d-flex align-items-baseline justify-content-center">
        <div class="row">
            <div class="col-md-12">
                <h5 class="text-center">{{version}} | &copy;<a class="ligaitem"
                                                              [href]="'https://www.facebook.com/KoreIngenieria/'"
                                                              target="_blank">Kore Ingenieria</a> | Car Comercializadora | {{anio}}</h5>
            </div>
        </div>
    </div>

    <div class="espacio"></div>

</div>
